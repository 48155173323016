
body {
    overflow-x: hidden !important;
}
  
#sidebar-wrapper {
    min-height: 100vh;
    margin-left: -15rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
    /* padding: 1.040rem 1.25rem; */
    font-size: 1.2rem;
}

.logo{
    width: 40%;
}
.sidebar-heading{
    height: 62.5px;
    vertical-align: middle;
    text-align: center;
}

.helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.helper img {
    vertical-align: middle;
    max-height: 25px;
    max-width: 160px;
}

#sidebar-wrapper .list-group {
    width: 15rem;
}

#page-content-wrapper {
    min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
}

.list-group-item-action{
    border:0px;
}

.menu-title{
    text-decoration: none !important;
}
.menu-child{
    text-decoration: none !important;
}

.menu-title:hover .angle-menu{
    color:#F95701;
}

.menu-title:hover .icon-menu{
    background-color: #F95701;
}

.menu-title:hover .parent-item{
    border: 2px solid #F95701;
    border-left: 0px;
}
.menu-title:hover .icon{
    color: black;
}

/* Active menu parent */
.menu-title.active .angle-menu{
    color:red;
}

.menu-title.active .icon-menu{
    background-color: red;
}

.menu-title.active .parent-item{
    border: 2px solid #F95701;
    border-left: 0px;
}

.menu-title {
    color:#000000 !important;
}

.first-item {
    border-top-right-radius: 0 !important;
}

.margin-menu{
    margin-bottom: 4px;
}

.parent-item{
    border: 2px solid #CCCCCC;
    border-left: 0px;
    width: auto;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.child-item{
    border: 2px solid #e5e5e5;
    background-color: #e5e5e5;
    border-left: 0px;
    width: auto;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.child-item:hover .icon-menu{
    border: 2px solid black;
    background-color: black;
    border-left: 0px;
}

.child-item:hover .icon{
    color:#F95701;
}

.child-item:hover {
    border: 2px solid black;
    background-color: white;
    border-left: 0px;
}
.child-item .text-menu {
   color:black;
}
.item-active .child-item {
    border: 2px solid black;
    background-color: white;
    border-left: 0px;
    transition: border-bottom .5s ease-in-out;
}

.child-item .icon-menu{
    background-color: #CCCCCC;
}

.child-item .icon{
    color: black;
}

.item-active .child-item .icon{
    color:red;
}

.item-active .child-item .icon-menu{
    background-color: black;
}

.icon-menu{
    margin-top: -2px;
    margin-bottom: -2px;
    display: flex;
    align-items: center;
    text-align: center;
    background-color: #CCCCCC;
    color:black;
    width: 15%;
    height: 40px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.icon-menu .icon{
    margin-left: 5px;
}

.text-menu{
    width: 72%;
    display: flex;
    align-items: center;
    font-weight: bold;
}
.text-menu .text{
    margin-left: 10px;
}
.angle-menu{
    color: #CCCCCC;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 768px) {

    #sidebar-wrapper {
        margin-left: 0;
    }

    #page-content-wrapper {
        min-width: 0;
        width: 100%;
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: -17.2rem;
    }

}