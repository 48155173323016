.hr-theme-slash-2 {
    display: flex; 
}
.hr-line {
    width: 100%;
    position: relative;
    margin: 10px;
    border-bottom: 1px solid red;
}
.hr-icon {
    position: relative;
    top: 3px;
}

.icon-line {
    color: #f96332;
}